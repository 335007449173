import * as React from 'react';

import {fetchDonation} from '../services/donation';
import withLoading, {WithLoadingProps} from '../withLoading';
import DonationItem from '../components/DonationItem';
import DonationSearch from '../components/DonationSearch';

import InfiniteScroll from 'react-infinite-scroller';

export interface ListDonationProps extends WithLoadingProps {

}

export interface ListDonationState {
  donations: Array<any>,
  meta: any,
  selected: string,
  query: string,
}

class ListDonation extends React.Component<ListDonationProps, ListDonationState> {
  state = {
    donations: [],
    meta: {
      current_page: 1,
      form: 1,
      last_page: 1
    },
    selected: '',
    query: ''
  };

  onFetchDonation = async (query: string = '', page: number = 1, append: boolean = false) => {
    try {
      this.props.onShowLoading();

      const response = await fetchDonation(query, page);

      if (response.data.length < 1) {
        this.props.onHideLoading();
        return;
      }

      if (append) {
        const donations = [...this.state.donations].concat(response.data);

        this.setState({donations, meta: response.meta});
      } else {
        this.setState({donations: response.data, meta: response.meta});
      }

      this.props.onHideLoading();
    } catch (e) {
      this.props.onHideLoading();
    }
  };

  async componentDidMount() {
    await this.onFetchDonation();
  }

  onSelectedItem = (event: React.MouseEvent<HTMLDivElement>, data: any) => {
    event.preventDefault();

    this.setState({selected: data.index === this.state.selected ? '' : data.index});
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>, data: any) => {
    event.preventDefault();

    this.setState(prevState => (
      {...prevState, [data.name]: data.value}
    ))
  };

  onHandleSearch = async (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
    event.preventDefault();

    await this.onFetchDonation(data.query || '');
  };

  onNextPage = async (page: number) => {
    await this.onFetchDonation(this.state.query, page, true);
  };

  render() {
    return (<React.Fragment>
      <DonationSearch
        query={this.state.query}
        onChange={this.onChange}
        onSearch={this.onHandleSearch}
      />
      <InfiniteScroll
        pageStart={1}
        loadMore={this.onNextPage}
        hasMore={this.state.meta.current_page !== this.state.meta.last_page}
        initialLoad={false}
      >
        {this.state.donations.length > 0 && this.state.donations.map((donation: any) => (
          <DonationItem
            key={donation.id}
            donation={donation}
            selected={this.state.selected}
            onSelected={this.onSelectedItem}
          />
        ))}
      </InfiniteScroll>
    </React.Fragment>);
  }
}

export default withLoading(ListDonation)
