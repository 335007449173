import * as React from 'react';

import {Redirect, Route, RouteProps, Switch} from 'react-router-dom';
import {isAuthentication} from './utils/token';

import Home from './pages/Home';
import Login from './pages/Login';
import InputDonation from './pages/InputDonation';
import InputDonor from './pages/InputDonor';
import ListDonation from './pages/ListDonation';
import ListDonorDonation from './pages/ListDonorDonation';
import ListDonor from './pages/ListDonor';

const AuthRoute = (props: RouteProps) => {
  if (isAuthentication()) {
    return <Route {...props} />
  }

  return <Redirect to='/login'/>
};

export default function () {
  return (
    <Switch>
      <AuthRoute exact path='/' component={Home}/>
      <AuthRoute exact path='/donation' component={ListDonation}/>
      <AuthRoute exact path='/donation/create' component={InputDonation}/>
      <AuthRoute exact path='/donation/donor' component={ListDonorDonation}/>
      <AuthRoute exact path='/donor' component={ListDonor}/>
      <AuthRoute exact path='/donor/create' component={InputDonor}/>
      <Route exact path='/login' component={Login}/>
    </Switch>
  );
}
