import * as React from 'react';
import queryString from 'query-string';
import withLoading, {WithLoadingProps} from '../withLoading';
import DonationForm from '../components/DonationForm';
import DonationDonorInfo from '../components/DonationDonorInfo';

export interface InputDonationProps extends WithLoadingProps {

}

export interface ListDonationState {
  type: any,
  donor_id: any,
  donor_name: any,
  donor_identification_number: any
}

class InputDonation extends React.Component<InputDonationProps, ListDonationState> {
  constructor(props: InputDonationProps) {
    super(props);

    // eslint-disable-next-line no-restricted-globals
    const query = queryString.parse(location.search);

    this.state = {
      type: query.type ? query.type : 'TRANSFER',
      donor_id: query.id,
      donor_identification_number: query.identification_number,
      donor_name: query.name
    };
  }

  render() {
    return (
      <React.Fragment>
        <DonationDonorInfo
          id={this.state.donor_id}
          name={this.state.donor_name}
          identification_number={this.state.donor_identification_number}
        />
        <DonationForm
          type={this.state.type}
          donor_id={this.state.donor_id}
          onHideLoading={this.props.onHideLoading}
          onShowLoading={this.props.onShowLoading}
          onHideMessage={this.props.onHideMessage}
          onShowMessage={this.props.onShowMessage}
        />
      </React.Fragment>
    );
  }
}

export default withLoading(InputDonation)
