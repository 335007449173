import * as React from 'react';
import {createRef} from 'react';

import moment from 'moment';

import http from '../utils/http';
import error from '../utils/error';
import {uuid4} from '../utils/uuid';
import {getSession} from '../utils/token';

import {WithLoadingProps} from '../withLoading';

import ListDonationDetail from '../components/ListDonationDetail';

import {BankAccountDropdown, FundingDropdown, OutletDropdown, ProgramDropdown} from './Dropdown';
import {Button, Icon, Divider, Form, FormField, FormInput, Message} from 'semantic-ui-react';

export interface DonationFormState {
  field: any,
  error: object
}

export interface DonationFormProps extends WithLoadingProps {
  type: string,
  donor_id: string
}

// noinspection TypeScriptValidateTypes
export default class DonationForm extends React.Component<DonationFormProps, DonationFormState> {
  session: any = {};

  fileInput = createRef<HTMLInputElement>();

  state = {
    field: {
      currency: 'IDR',
      currency_rate: 1,
      type: '',
      donor_id: '',
      outlet_id: '',
      employee_id: '',
      volunteer_id: '',
      branch_id: '',
      funding_type_id: '',
      program_id: '',
      amount: 0,
      total_amount: 0,
      program: {},
      funding: {},
      details: [],
      bank_account_id: '',
      date: moment().format('YYYY-MM-DD'),
      file: '',
      file_name: ''
    },
    error: error.clear()
  };

  constructor(props: DonationFormProps) {
    super(props);

    this.session = getSession();
  }

  static getDerivedStateFromProps(props: DonationFormProps, state: DonationFormState) {
    if (props.donor_id !== state.field.donor_id || props.type !== state.field.type) {
      const field = {...state.field, donor_id: props.donor_id, type: props.type};

      return {field};
    }

    return null;
  }

  onResetState = () => {
    this.setState({
      field: {
        currency: 'IDR',
        currency_rate: 1,
        type: '',
        donor_id: '',
        outlet_id: '',
        employee_id: '',
        volunteer_id: '',
        branch_id: '',
        funding_type_id: '',
        program_id: '',
        amount: 0,
        total_amount: 0,
        program: {},
        funding: {},
        details: [],
        bank_account_id: '',
        date: moment().format('YYYY-MM-DD'),
        file: '',
        file_name: ''
      }
    });
  };

  onChangeProgram = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    event.preventDefault();

    const field = {...this.state.field, [data.name]: data.value, program: data.program};

    this.setState({field, error: this.state.error.clear(data.name)});
  };

  onChangeFunding = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    event.preventDefault();

    const field = {...this.state.field, [data.name]: data.value, funding: data.funding};

    this.setState({field, error: this.state.error.clear(data.name)});
  };

  onChangeOutlet = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    event.preventDefault();

    const field = {
      ...this.state.field,
      [data.name]: data.value,
      employee_id: data.outlet.employee_id,
      branch_id: data.outlet.branch_id
    };

    this.setState({field, error: this.state.error.clear(data.name)});
  };

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    event.preventDefault();

    const field = {...this.state.field, [data.name]: data.value};

    this.setState({field, error: this.state.error.clear(data.name)});
  };

  onHandleAddButton = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();

    this.props.onShowLoading();

    const field = {...this.state.field};

    const {funding_type_id, program_id, amount, program, funding, total_amount} = field;

    if (funding_type_id === '') {
      this.props.onHideLoading();
      return;
    }

    if (Number(amount) <= 0 || isNaN((Number(total_amount) + Number(amount)))) {
      this.props.onHideLoading();
      return;
    }

    const isExist = field.details.filter((detail: any) => {
      return detail.funding_type_id === funding_type_id && detail.program_id === program_id;
    });

    if (isExist.length > 0) {
      this.props.onHideLoading();

      return;
    }

    // @ts-ignore
    field.details.push({id: uuid4(), funding_type_id, program_id, amount, program, funding});

    field.total_amount = field.details.map((detail: any) => {
      return detail.amount;
    }).reduce((total, amount) => {
      return total + Number(amount);
    }, 0);

    this.setState({
      field: {...field, funding_type_id: '', program_id: '', amount: 0, program: {}, funding: {}}
    });

    this.props.onHideLoading();
  };

  onHandleDelete = (event: React.SyntheticEvent<HTMLElement>, detail: any) => {
    event.preventDefault();

    const details = this.state.field.details.slice();

    const removedIndex: any = details.findIndex((item: any) => {
      return item.id === detail.id;
    });

    const removedElement: any = details.splice(removedIndex, 1);

    this.setState({
      field: {...this.state.field, details, total_amount: this.state.field.total_amount - removedElement[0].amount}
    });
  };

  stateToParams = (): any => {
    const volunteer = this.session.volunteer_id || '';
    const {file, date, bank_account_id, donor_id, employee_id, branch_id, outlet_id, volunteer_id, type: transaction_type, total_amount, currency, currency_rate} = {
      ...this.state.field,
      employee_id: this.state.field.employee_id === '' ? this.session.employee_id : this.state.field.employee_id,
      branch_id: this.state.field.branch_id === '' ? this.session.branch_id : this.state.field.branch_id,
      volunteer_id: this.state.field.volunteer_id === '' ? volunteer : this.state.field.volunteer_id
    };

    const details: any = this.state.field.details.map((detail: any) => {
      const {funding_type_id, program_id, amount} = detail;

      return {funding_type_id, program_id, amount: Number(amount)};
    });

    const form = new FormData;

    form.append('donor_id', donor_id);
    form.append('employee_id', employee_id);
    form.append('branch_id', branch_id);
    form.append('outlet_id', outlet_id);
    form.append('volunteer_id', volunteer_id);
    form.append('transaction_type', transaction_type);
    form.append('total_amount', total_amount.toString());
    form.append('transaction_type', transaction_type);
    form.append('details', JSON.stringify(details));
    form.append('currency', currency.toString());
    form.append('currency_rate', currency_rate.toString());

    //@ts-ignore
    form.append('file', file);

    if (transaction_type !== 'CASH') {
      form.append('date', date);
      form.append('bank_account_id', bank_account_id);
    }

    return form;
  };

  onHandleSave = async (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();

    this.props.onShowLoading();

    try {
      const {data: response} = await http.post('/donations', this.stateToParams());

      this.props.onHideLoading();

      this.props.onShowMessage(response.message);

      this.onResetState();
    } catch (e) {
      this.props.onHideLoading();

      this.props.onShowMessage(e.message);
    }
  };

  onHandleSelectFile = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();

    if (this.fileInput.current) {
      this.fileInput.current.click();
    }
  };

  onChangeFile = (event: any) => {
    const value = event.target.files[0];

    const field = {...this.state.field, file_name: value.name, file: value};

    this.setState({field})
  };

  render() {
    return <Form error={this.state.error.any()}>
      <FormField error={this.state.error.has('funding_type_id')}>
        <FundingDropdown
          selectedValue={this.state.field.funding_type_id}
          onChange={this.onChangeFunding}
        />
        <Message error content={this.state.error.get('funding_type_id')}/>
      </FormField>
      <FormField error={this.state.error.has('program_id')}>
        <ProgramDropdown
          selectedValue={this.state.field.program_id}
          onChange={this.onChangeProgram}
        />
        <Message error content={this.state.error.get('program_id')}/>
      </FormField>
      <FormField error={this.state.error.has('program_id')}>
        <FormInput
          error={this.state.error.has('program_id')}
          type='number'
          name='amount'
          value={this.state.field.amount}
          onChange={this.onChange}
        />
        <Message error content={this.state.error.get('program_id')}/>
      </FormField>
      <Button
        primary={true}
        fluid={true}
        size='large'
        onClick={this.onHandleAddButton}
      >
        Tambah
      </Button>

      {this.state.field.details.length > 0 && <React.Fragment>
          <ListDonationDetail
              details={this.state.field.details}
              onDeleteDetail={this.onHandleDelete}
          />

          <Divider/>
          <FormField error={this.state.error.has('outlet_id')}>
              <OutletDropdown
                  selectedValue={this.state.field.outlet_id}
                  onChange={this.onChangeOutlet}
              />
              <Message error content={this.state.error.get('outlet_id')}/>
          </FormField>
        {this.state.field.type === 'TRANSFER' && <React.Fragment>
            <FormField error={this.state.error.has('bank_account_id')}>
                <BankAccountDropdown
                    selectedValue={this.state.field.bank_account_id}
                    onChange={this.onChange}
                />
                <Message error content={this.state.error.get('bank_account_id')}/>
            </FormField>
            <FormField error={this.state.error.has('date')}>
                <FormInput
                    error={this.state.error.has('date')}
                    type='date'
                    name='date'
                    value={this.state.field.date}
                    onChange={this.onChange}
                    placeholder='Masukan tanggal transfer'
                    max={moment().format('YYYY-MM-DD')}
                    attern='[0-9]{4}-[0-9]{2}-[0-9]{2}'
                />
                <Message error content={this.state.error.get('date')}/>
            </FormField>
            <FormField error={this.state.error.has('file')}>
                <FormInput
                    action={
                      <Button
                        onClick={this.onHandleSelectFile}
                        icon
                        labelPosition='left'
                        type='button'
                      >
                        <Icon name='file text outline'/> File
                      </Button>}
                    fluid
                    placeholder='Select file'
                    type='text'
                    error={this.state.error.has('file')}
                    value={this.state.field.file_name}
                    readOnly
                />
                <input
                    type='file'
                    hidden
                    name='file'
                    ref={this.fileInput}
                    onChange={this.onChangeFile}
                />
            </FormField>
        </React.Fragment>}

          <Button
              primary={true}
              fluid={true}
              size='large'
              onClick={this.onHandleSave}
          >
              Simpan
          </Button>
      </React.Fragment>}
    </Form>
  }
}
