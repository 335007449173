import * as React from 'react';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardMeta,
  Icon,
  Image
} from 'semantic-ui-react';

import {getSession} from '../utils/token';
import 'semantic-ui-css/components/card.min.css';
import 'semantic-ui-css/components/image.min.css';

export interface DonorItemProps {
  donor: any
}

export default function (props: DonorItemProps) {
  const session = getSession();

  const {id, name, identification_number} = props.donor;

  const query = queryString.stringify({id, name, identification_number});

  return (
    <Card fluid>
      <CardContent>
        <Image floated='right' size='mini' src='https://react.semantic-ui.com/images/avatar/large/steve.jpg' />
        <CardMeta style={{marginBottom: '.5em'}}>
          {props.donor.identification_number} | {props.donor.branch.name}
        </CardMeta>
        <CardHeader>{props.donor.name}</CardHeader>
        <CardDescription>
          <Icon name='phone'/> {props.donor.phone ? props.donor.phone.number : '-'} | <Icon name='mail'/> {props.donor.email || '-'}
        </CardDescription>
      </CardContent>
      <Card.Content extra>
        <ButtonGroup size='mini' fluid={true}>
          <Button basic color='green' size='mini' disabled>
            View
          </Button>
          <Button basic color='green' size='mini' as={Link} to={`/donation/create?type=CASH&${query}`}>
            Cash
          </Button>
          <Button basic color='green' size='mini' as={Link} to={`/donation/create?type=TRANSFER&${query}`}>
            Transfer
          </Button>
        </ButtonGroup>
      </Card.Content>
    </Card>
  );
}
