import * as React from 'react';
import moment from 'moment';
import ListDonationDetail from '../components/ListDonationDetail';
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  Form,
  FormGroup,
  FormInput,
  Label
} from 'semantic-ui-react';

import 'semantic-ui-css/components/accordion.min.css';
import 'semantic-ui-css/components/label.min.css';

export interface DonationItemProps {
  donation?: any,
  selected?: string,
  onSelected: (event: React.MouseEvent<HTMLDivElement>, data: any) => void
}

export default function (props: DonationItemProps) {
  return (
    <Card fluid color={props.donation.id === props.selected ? 'green' : 'grey'}>
      <Accordion fluid>
        <AccordionTitle
          active={props.donation.id === props.selected}
          index={props.donation.id}
          onClick={props.onSelected}
        >
          <CardContent style={{margin: '.5em'}}>
            <CardDescription style={{marginBottom: '.5em'}}>
              <strong>{props.donation.identification_number}</strong>
            </CardDescription>
            <CardHeader style={{marginBottom: '.5em'}}>{props.donation.donor.name}</CardHeader>
            <CardDescription>
              <Label>{moment(props.donation.date).format('DD MMMM YYYY')}</Label>
              <Label>Rp.{props.donation.total_amount.toLocaleString()}</Label>
              <Label>{props.donation.transaction_status}</Label>
            </CardDescription>
          </CardContent>
        </AccordionTitle>
        <AccordionContent active={props.donation.id === props.selected}>
          <CardContent style={{margin: '.5em'}}>
            <Form>
              {(props.donation.outlet || props.donation.bankAccount) && <FormGroup widths='equal'>
                {props.donation.outlet && <FormInput value={props.donation.outlet.name} fluid size='mini'/>}
                {props.donation.bankAccount &&
                <FormInput value={props.donation.bankAccount.account_number} fluid size='mini'/>}
              </FormGroup>}
              <ListDonationDetail details={props.donation.details}/>
            </Form>
          </CardContent>
        </AccordionContent>
      </Accordion>
    </Card>
  )
}