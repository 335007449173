import decode from 'jwt-decode';

export const setAuth = (token: string) => {
  const {exp} = decode(token);

  localStorage.setItem('token', token);
  localStorage.setItem('expired', exp);
};

export const getSession = () => {
  const token = localStorage.getItem('token');

  if(token) {
    const {employee_id, branch_id, volunteer_id} = decode(token);

    return {employee_id, branch_id, volunteer_id};
  }

  return {};
};

export const isTokenExpired = () => {
  const currentTime = Date.now() / 1000;

  const expired = localStorage.getItem('expired') || 0;

  const expiresIn = +expired;

  return expiresIn > currentTime;
};

export const isAuthentication = () => {
  const token = localStorage.getItem('token');

  if (token) {
    return isTokenExpired()
  }

  return false;
};

export const getToken = () => {
  return localStorage.getItem('token');
};
