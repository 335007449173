import * as React from 'react';
import {Menu as SemanticMenu, MenuItem, MenuItemProps} from 'semantic-ui-react';

import 'semantic-ui-css/components/menu.min.css';

export interface MenuProps {
  header: String,
  onSidebarOpen: (event: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => void
}

export default function (props: MenuProps) {
  return (
    <SemanticMenu secondary fixed='top' fluid inverted color='green' size='massive' style={{zIndex: 999}}>
      <MenuItem header icon='bars' onClick={props.onSidebarOpen}/>
      <MenuItem header content={props.header}/>
    </SemanticMenu>
  )
}
