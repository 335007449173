import * as React from 'react';
import {Button, Input, Message} from 'semantic-ui-react';

export interface DonationSearchProps {
  onSearch: (event: React.MouseEvent<HTMLButtonElement>, data: any) => void,
  onChange: (event: React.ChangeEvent<HTMLInputElement>, data: any) => void,
  query: string
}

export default function (props: DonationSearchProps) {
  const [visible, setVisible] = React.useState(false);

  function onSearch(event: any) {
    event.preventDefault();

    setVisible(false);

    props.onSearch(event, {query: props.query});
  }

  return <React.Fragment>
    <Input
      action
      fluid
      name='query'
      type='text'
      placeholder='Search...'
      style={{marginTop: '16px', marginBottom: '16px'}}
      value={props.query}
      onChange={props.onChange}
      onClick={() => setVisible(true)}
    >
      <input/>
      <Button
        type='submit'
        icon='search'
        onClick={onSearch}/>
    </Input>
    {visible && <Message
        warning
        visible
        onDismiss={() => setVisible(false)}
        header='Donasi dapat dicari dengan :'
        list={[
          'ID Donasi, ex: 2019010100000',
          'Tanggal, ex: 2019-01-01.',
          'Status, ex: PENDING',
          'Total Donasi, ex: 1000000',
          'Name, ex: Nuradiyana',
          'Email, ex: nuradiyana@izi.or.id',
          'Address, ex: Jalan Raya Condet, hindari nama kota.',
        ]}
    />}
  </React.Fragment>
}