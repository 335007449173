import * as React from 'react';
import http from '../utils/http';
import withLoading, {WithLoadingProps} from '../withLoading';

import {Card, CardContent, CardHeader, Grid, GridColumn, GridRow, Statistic, StatisticValue} from 'semantic-ui-react';
import 'semantic-ui-css/components/statistic.min.css';

class Home extends React.Component<WithLoadingProps> {
  state = {
    summaries: [
      {transaction_status: 'PENDING', summary: 0, count: 0},
      {transaction_status: 'CANCEL', summary: 0, count: 0},
      {transaction_status: 'VERIFIED', summary: 0, count: 0}
    ]
  };

  async componentDidMount() {
    this.props.onShowLoading();

    const {data} = await http.get('/profiles/achievement/status');

    const summaries = this.state.summaries.map((summary: any) => {
      const index = data.findIndex((item: any) => item.transaction_status === summary.transaction_status);

      if (index > -1) {
        return data[index];
      }

      return summary;
    });

    this.setState({summaries});

    this.props.onHideLoading();
  }

  render() {
    return (
      <Grid style={{paddingTop: '1em'}}>
        {this.state.summaries.map(({transaction_status, summary}: any) => (
          <GridRow key={transaction_status}>
            <GridColumn textAlign='center'>
              <Statistic as={Card} fluid style={{paddingBottom: '0.5em'}} color='green'>
                <CardContent>
                  <CardHeader>{transaction_status}</CardHeader>
                </CardContent>
                <StatisticValue>{Number(summary).toLocaleString()}</StatisticValue>
              </Statistic>
            </GridColumn>
          </GridRow>
        ))}
      </Grid>
    );
  }
}

export default withLoading(Home);
