import * as React from 'react';

import http from '../utils/http';

import {Dropdown} from 'semantic-ui-react';

export interface BankAccountDropdownState {
  accounts: Array<any>,
  selectedValue?: string
}

export interface BankAccountDropdownProps {
  selectedValue?: string,
  onChange: any
}

export default class BankAccountDropdown extends React.Component<BankAccountDropdownProps, BankAccountDropdownState> {
  state = {
    accounts: [],
    selectedValue: ''
  };

  async componentDidMount() {
    const {data: response} = await http.get('/funding/accounts?paginated=false');

    this.setState({accounts: response.data});
  }

  static getDerivedStateFromProps(nextProps: BankAccountDropdownProps, prevState: BankAccountDropdownState) {
    if (nextProps.selectedValue !== prevState.selectedValue) {
      return ({selectedValue: nextProps.selectedValue});
    }

    return null;
  };

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    event.preventDefault();

    const index = this.state.accounts.findIndex((account: any) => account.id === data.value);

    data.account = index >= 0 ? this.state.accounts[index] : {};

    this.props.onChange(event, data);
  };

  render() {
    const defaultValue = {key: '0', value: '', text: 'Pilih rekening'};

    const options = this.state.accounts.length >= 1 ? [defaultValue].concat(this.state.accounts.map((account: any) => ({
      key: account.id,
      value: account.id,
      text: `${account.bank.name} - ${account.account_number}`
    }))) : [defaultValue];

    return (
      <Dropdown
        fluid
        selection
        clearable
        options={options}
        name='bank_account_id'
        placeholder='Pilih rekening'
        value={this.state.selectedValue}
        onChange={this.onChange}
      />
    );
  }
}
