import * as React from 'react';
import 'semantic-ui-css/components/table.min.css';
import {Button, Header, HeaderContent, HeaderSubheader, Table, TableBody, TableCell, TableRow} from 'semantic-ui-react';

export interface ListDonationDetailProps {
  details: any,
  onDeleteDetail?: (event: React.MouseEvent<HTMLButtonElement>, detail: any) => void
}

export default function (props: ListDonationDetailProps) {
  return (
    <Table celled compact>
      <TableBody>
        {props.details.length > 0 && props.details.map((detail: any) => (
          <TableRow key={detail.id}>
            <TableCell>
              <Header as='h4' image>
                <HeaderContent> {detail.funding ? detail.funding.name || '-' : '-'}
                  <HeaderSubheader>{detail.program ? detail.program.name || '-' : ''}</HeaderSubheader>
                </HeaderContent>
              </Header>
            </TableCell>
            <TableCell>{detail.amount.toLocaleString() || 0}</TableCell>
            {props.onDeleteDetail && <TableCell>
              <Button size='tiny' fluid color='red' basic onClick={event => {
                // @ts-ignore
                props.onDeleteDetail(event, detail)
              }}>
                Hapus
              </Button>
            </TableCell>}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
