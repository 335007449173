import React, {Component, createContext, Fragment} from 'react';
import {RouteComponentProps, withRouter} from 'react-router';

import {MenuItemProps} from 'semantic-ui-react';

import Sidebar from './components/Sidebar';
import Menu from './components/Menu';

import {isAuthentication} from './utils/token';

export interface AppState {
  visible: boolean,
  header: string,
  selectedMenu: string,
  isLogin: boolean
}

export const AppContext = createContext({
  header: 'Dashboard'
});

class App extends Component<RouteComponentProps, AppState> {
  state: AppState = {
    visible: false,
    header: 'Dashboard',
    selectedMenu: 'dashboard',
    isLogin: true
  };

  onSidebarOpen = () => this.setState({visible: !this.state.visible});

  onSidebarClose = () => this.setState({visible: false});

  onHandleLogout = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    localStorage.clear();

    this.setState({isLogin: false, visible: false});

    this.props.history.push('/login');
  };

  onClickMenu = (event: React.MouseEvent<HTMLAnchorElement>, name: string, data: MenuItemProps, path: string) => {
    event.preventDefault();

    this.setState({
      selectedMenu: name,
      visible: false,
      header: data.content ? data.content.toString() : this.state.header
    });

    this.props.history.push(path)
  };

  render() {
    return (
      <Fragment>
        <AppContext.Provider value={{header: this.state.header}}>
          {isAuthentication() && <Menu
            header={this.state.header}
            onSidebarOpen={this.onSidebarOpen}
          />}
          <Sidebar
            visible={this.state.visible}
            selectedMenu={this.state.selectedMenu}
            onClickMenu={this.onClickMenu}
            onSidebarClose={this.onSidebarClose}
            onHandleLogout={this.onHandleLogout}
          />
        </AppContext.Provider>
      </Fragment>
    );
  }
}

export default withRouter(App);
