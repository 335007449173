import * as React from 'react';

import {Dropdown} from 'semantic-ui-react';

export interface DonorTypeDropdownProps {
  selectedValue: string,
  onChange: any
}

export default function (props: DonorTypeDropdownProps) {

  const options = [
    {key: 'PERSONAL', value: 'PERSONAL', text: 'PERSONAL'},
    {key: 'COMMUNITY', value: 'COMMUNITY', text: 'COMMUNITY'},
    {key: 'COMPANY', value: 'COMPANY', text: 'COMPANY'}
  ];

  return (
    <Dropdown
      fluid
      selection
      options={options}
      name='type'
      placeholder='Pilih tipe donatur'
      value={props.selectedValue}
      onChange={props.onChange}
    />
  );
}
