import * as React from 'react';

import http from '../utils/http';
import error from '../utils/error';
import {getSession} from '../utils/token';

import {RouteComponentProps, withRouter} from 'react-router';
import withLoading, {WithLoadingProps} from '../withLoading';

import {DonorTypeDropdown, OutletDropdown} from '../components/Dropdown';
import {Button, Form, FormField, FormInput, FormTextArea, Input, Message} from 'semantic-ui-react';

export interface InputDonorProps extends WithLoadingProps, RouteComponentProps {

}

export interface InputDonorState {
  field: Object,
  error: Object
}

class InputDonor extends React.Component<InputDonorProps, InputDonorState> {
  session: any = {};

  state = {
    field: {
      type: 'PERSONAL',
      name: '',
      email: '',
      number: '',
      address: '',
      outlet_id: '',
      employee_id: '',
      volunteer_id: '',
      branch_id: ''
    },
    error: error.clear()
  };

  constructor(props: any) {
    super(props);

    this.session = getSession();
  }

  onChangeOutlet = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    event.preventDefault();

    const field = {
      ...this.state.field,
      [data.name]: data.value,
      employee_id: data.outlet.employee_id,
      branch_id: data.outlet.branch_id
    };

    this.setState({field, error: this.state.error.clear(data.name)});
  };

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    event.preventDefault();

    const field = {...this.state.field, [data.name]: data.value};

    this.setState({field, error: this.state.error.clear(data.name)});
  };

  onSubmit = async (event: React.SyntheticEvent<HTMLElement>) => {
    this.props.onShowLoading();

    try {
      event.preventDefault();

      const params = {
        ...this.state.field,
        number: this.state.field.number === '' ? '' : `+62${this.state.field.number}`,
        employee_id: this.state.field.employee_id === '' ? this.session.employee_id : this.state.field.employee_id,
        branch_id: this.state.field.branch_id === '' ? this.session.branch_id : this.state.field.branch_id,
        volunteer_id: this.state.field.volunteer_id === '' ? this.session.volunteer_id : this.state.field.volunteer_id
      };

      await http.post('/donors', params);

      this.props.onHideLoading();

      this.props.onShowMessage('Input donatur baru success!');

      this.setState({
        field: {
          type: 'PERSONAL',
          name: '',
          email: '',
          number: '',
          address: '',
          outlet_id: '',
          employee_id: '',
          volunteer_id: '',
          branch_id: ''
        }
      });
    } catch (e) {
      const {errors} = e.response.data;

      this.setState({error: error.record(errors)});

      this.props.onHideLoading();

      this.props.onShowMessage(e.message);
    }
  };

  render() {
    return (
      <Form size='large' onSubmit={this.onSubmit} error={this.state.error.any()} style={{marginTop: '20px'}}>
        <FormField error={this.state.error.has('outlet_id')}>
          <OutletDropdown
            selectedValue={this.state.field.outlet_id}
            onChange={this.onChangeOutlet}
          />
          <Message error content={this.state.error.get('outlet_id')}/>
        </FormField>
        <FormField required error={this.state.error.has('type')}>
          <DonorTypeDropdown
            selectedValue={this.state.field.type}
            onChange={this.onChange}
          />
          <Message error content={this.state.error.get('type')}/>
        </FormField>
        <FormField required error={this.state.error.has('name')}>
          <FormInput
            fluid
            icon='user'
            iconPosition='left'
            placeholder='Nama donatur'
            name='name'
            value={this.state.field.name}
            onChange={this.onChange}
          />
          <Message error content={this.state.error.get('name')}/>
        </FormField>
        <FormField required error={this.state.error.has('number')}>
          <Input
            fluid
            label="+62"
            placeholder='Nomor handphone (81318788271)'
            name='number'
            value={this.state.field.number}
            onChange={this.onChange}
          />
          <Message error content={this.state.error.get('number')}/>
        </FormField>
        <FormField required error={this.state.error.has('email')}>
          <FormInput
            fluid
            icon='mail'
            iconPosition='left'
            placeholder='Email donatur'
            name='email'
            value={this.state.field.email}
            onChange={this.onChange}
          />
          <Message error content={this.state.error.get('email')}/>
        </FormField>
        <FormField required error={this.state.error.has('address')}>
          <FormTextArea
            placeholder='Alamat donatur'
            name='address'
            value={this.state.field.address}
            onChange={this.onChange}
          />
          <Message error content={this.state.error.get('address')}/>
        </FormField>
        <Button
          primary={true}
          fluid={true}
          size='large'
        >
          Simpan
        </Button>
      </Form>
    );
  }
}

export default withLoading(
  withRouter(InputDonor)
)