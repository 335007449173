import * as React from 'react';
import {fetchDonor} from '../services/donor';
import withLoading, {WithLoadingProps} from '../withLoading';

import {RouteComponentProps, withRouter} from 'react-router';

import DonorItem from '../components/DonorItem';
import DonorSearch from '../components/DonorSearch';
import InfiniteScroll from 'react-infinite-scroller';

export interface ListDonorProps extends WithLoadingProps, RouteComponentProps {

}

export interface ListDonorState {
  donors: any,
  meta: any,
  query: string
}

class ListDonor extends React.Component<ListDonorProps, ListDonorState> {
  state = {
    donors: [],
    meta: {
      current_page: 1,
      form: 1,
      last_page: 1
    },
    query: ''
  };

  onFetchDonor = (query: string = '', page: number = 1, append: boolean = false) => {
    this.props.onShowLoading();

    fetchDonor(query, page).then(response => {
      if (response.data.length < 1) {
        this.props.onHideLoading();
        return;
      }

      if (append) {
        const donors = [...this.state.donors].concat(response.data);

        this.setState({donors, meta: response.meta});
      } else {
        this.setState({donors: response.data, meta: response.meta});
      }

      this.props.onHideLoading();
    }).catch(() => this.props.onHideLoading());
  };

  componentDidMount() {
    this.onFetchDonor();
  }

  onHandleSearch = (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
    event.preventDefault();

    this.onFetchDonor(data.query || '');
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>, data: any) => {
    event.preventDefault();

    this.setState(prevState => (
      {...prevState, [data.name]: data.value}
    ))
  };

  onNextPage = (page: number) => {
    this.onFetchDonor(this.state.query, page, true);
  };

  render() {
    return (
      <React.Fragment>
        <DonorSearch
          query={this.state.query}
          onChange={this.onChange}
          onSearch={this.onHandleSearch}/>

        <InfiniteScroll
          pageStart={1}
          loadMore={this.onNextPage}
          hasMore={this.state.meta.current_page !== this.state.meta.last_page}
          initialLoad={false}
        >
          {this.state.donors.length > 0 && this.state.donors.map((donor: any) => (
            <DonorItem
              key={donor.id}
              donor={donor}
            />
          ))}
        </InfiniteScroll>
      </React.Fragment>
    );
  }
}

export default withRouter(
  withLoading(ListDonor)
);
