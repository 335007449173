import * as React from 'react';
import {Card, CardContent, CardHeader, CardMeta, Image} from 'semantic-ui-react';

export interface DonationDonorInfoProps {
  id: any,
  name: any,
  identification_number: any
}

export default function (props: DonationDonorInfoProps) {
  return (
    <Card fluid>
      <CardContent>
        <Image floated='right' size='mini' src='https://react.semantic-ui.com/images/avatar/large/steve.jpg'/>
        <CardMeta style={{marginBottom: '.5em'}}>
          {props.identification_number}
        </CardMeta>
        <CardHeader>{props.name}</CardHeader>
      </CardContent>
    </Card>
  )
}
