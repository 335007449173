import * as React from 'react';

import {Dimmer, DimmerDimmable, Loader, Message} from 'semantic-ui-react';

import 'semantic-ui-css/components/dimmer.min.css';
import 'semantic-ui-css/components/loader.min.css';

export interface WithLoadingProps {
  onShowLoading: Function,
  onHideLoading: Function,
  onShowMessage: Function,
  onHideMessage: Function
}

export default function withLoading<Props>(Component: React.ComponentType<Props>) {
  return class extends React.Component<Props> {
    state = {
      loading: false,
      messages: {
        visible: false,
        content: 'Default content message'
      }
    };

    onShowLoading = () => {
      this.setState({loading: true});
    };

    onHideLoading = () => {
      this.setState({loading: false});
    };

    onShowMessage = (content: string) => {
      this.setState({messages: {visible: true, content}});
    };

    onHideMessage = () => {
      this.setState({messages: {visible: false, content: 'Default content message'}})
    };

    render() {
      return <DimmerDimmable
        blurring
        dimmed={this.state.loading}
        style={{padding: '0.5em', minHeight: this.state.loading ? '100vh' : '0', marginTop: this.state.loading ? '-36px' : '0'}}
      >
        <Dimmer active={this.state.loading}>
          <Loader active inline='centered'>Loading</Loader>
        </Dimmer>
        {this.state.messages.visible && <Message
            positive
            onDismiss={this.onHideMessage}
            content={this.state.messages.content}
        />}
        <Component
          {...this.props}
          onShowLoading={this.onShowLoading}
          onHideLoading={this.onHideLoading}
          onShowMessage={this.onShowMessage}
          onHideMessage={this.onHideMessage}
        />
      </DimmerDimmable>;
    }
  }
}