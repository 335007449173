import * as React from 'react';

import http from '../utils/http';

import {Dropdown} from 'semantic-ui-react';

export interface FundingDropdownState {
  fundingTypes: Array<any>,
  selectedValue?: string
}

export interface FundingDropdownProps {
  selectedValue?: string,
  onChange: any
}

export default class FundingDropdown extends React.Component<FundingDropdownProps, FundingDropdownState> {
  state = {
    fundingTypes: [],
    selectedValue: ''
  };

  async componentDidMount() {
    const {data: response} = await http.get('/funding/types?paginated=false');

    this.setState({fundingTypes: response.data});
  }

  static getDerivedStateFromProps(nextProps: FundingDropdownProps, prevState: FundingDropdownState) {
    if (nextProps.selectedValue !== prevState.selectedValue) {
      return ({selectedValue: nextProps.selectedValue});
    }

    return null;
  };

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    event.preventDefault();

    const index = this.state.fundingTypes.findIndex((funding: any) => funding.id === data.value);

    data.funding = index >= 0 ? this.state.fundingTypes[index] : {};

    this.props.onChange(event, data);
  };

  render() {
    const defaultValue = {key: '0', value: '', text: 'Pilih jenis dana'};

    const options = this.state.fundingTypes.length >= 1 ? [defaultValue].concat(this.state.fundingTypes.map((funding: any) => ({
      key: funding.id,
      value: funding.id,
      text: funding.name
    }))) : [defaultValue];

    return (
      <Dropdown
        fluid
        selection
        clearable
        options={options}
        name='funding_type_id'
        placeholder='Pilih jenis dana'
        value={this.state.selectedValue}
        onChange={this.onChange}
      />
    );
  }
}
