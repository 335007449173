import React from 'react';
import ReactDOM from 'react-dom';

import 'semantic-ui-css/components/reset.min.css';
import 'semantic-ui-css/components/site.min.css';
import 'semantic-ui-css/components/message.min.css';

import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/segment.min.css';
import 'semantic-ui-css/components/grid.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/input.min.css';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/label.min.css';
import 'semantic-ui-css/components/divider.min.css';
import 'semantic-ui-css/components/transition.min.css';

import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';

ReactDOM.render(<Router>
  <App/>
</Router>, document.getElementById('root'));
