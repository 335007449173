import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';

import {
  Button,
  Form,
  FormField,
  FormInput,
  Grid,
  GridColumn,
  Header,
  InputOnChangeData,
  Message,
  Segment
} from 'semantic-ui-react';

import {login} from '../services/auth';
import {setAuth} from '../utils/token';
import error from '../utils/error';

export interface LoginState {
  email: string,
  password: string,
  loading: boolean,
  error: any
}

class Login extends React.Component<RouteComponentProps, LoginState> {
  state = {
    email: '',
    password: '',
    loading: false,
    error: error.clear()
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    event.preventDefault();

    this.setState(prevState => ({
      ...prevState, [data.name]: data.value, error: this.state.error.clear(data.name)
    }))
  };

  onHandleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    try {
      this.setState({loading: true});

      const data = await login(this.state.email, this.state.password);

      setAuth(data.token);

      this.setState({loading: false});

      this.props.history.push('/');
    } catch (e) {
      const {errors} = e.response.data;

      this.setState({loading: false, error: error.record(errors)});
    }
  };

  render() {
    return (
      <Grid textAlign='center' verticalAlign='middle' style={{paddingTop: '25%'}}>
        <GridColumn style={{maxWidth: 450}}>
          <Header as='h2' color='blue' textAlign='center'>
            Log-in to your account
          </Header>
          <Form size='large' error={this.state.error.any()}>
            <Segment basic textAlign='left'>
              <FormField required error={this.state.error.has('email')}>
                <FormInput
                  fluid
                  icon='user'
                  iconPosition='left'
                  placeholder='E-mail address'
                  name='email'
                  error={this.state.error.has('email')}
                  onChange={this.onChange}
                />
                <Message error content={this.state.error.get('email')}/>
              </FormField>
              <FormField required error={this.state.error.has('password')}>
                <FormInput
                  fluid
                  icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  type='password'
                  name='password'
                  onChange={this.onChange}
                />
                <Message error content={this.state.error.get('password')}/>
              </FormField>
              <Button
                loading={this.state.loading}
                primary={true}
                fluid={true}
                onClick={this.onHandleSubmit}
              >
                Login
              </Button>
            </Segment>
          </Form>
        </GridColumn>
      </Grid>
    );
  }
}

export default withRouter(Login);
