class Error {
  errors: any;

  constructor(errors = {}) {
    this.errors = errors;
  }

  has(field: string) {
    return this.errors.hasOwnProperty(field);
  }

  any() {
    return Object.keys(this.errors).length > 0;
  }

  get(field: string) {
    if (this.has(field) && this.errors[field]) {
      return this.errors[field][0];
    }
  }

  record(errors: object) {
    this.errors = errors;

    return this;
  }

  clear(field?: string) {
    if (field) {
      delete this.errors[field];

      return this;
    }

    this.errors = {};

    return this;
  }

  all() {
    return this.errors;
  }
}

const error = new Error;

export default error;
