import axios from 'axios';
import {getToken} from './token';
import {APP_URL_API} from './env';

const http = axios.create({
  baseURL: APP_URL_API,
  transformResponse: [
    ...axios.defaults.transformResponse
  ],
  transformRequest: [
    ...axios.defaults.transformRequest
  ]
});

http.defaults.headers.common.Accept = 'application/json';
http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
http.interceptors.request.use(config => {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config;
  },
  error => Promise.reject(error)
);

http.interceptors.response.use(response => response, error => {
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }

  return Promise.reject(error);
});

export default http;
