import * as React from 'react';

import Routes from '../routes';

import 'semantic-ui-css/components/sidebar.min.css';

import {
  Container,
  Menu,
  MenuItem,
  MenuMenu,
  Sidebar as SemanticSidebar,
  SidebarPushable,
  SidebarPusher
} from 'semantic-ui-react';

export interface SidebarProps {
  visible: boolean,
  selectedMenu: string,
  onClickMenu: Function,
  onSidebarClose: Function,
  onHandleLogout: any
}

export default function (props: SidebarProps) {
  return (
    <SidebarPushable as={Container}>
      <SemanticSidebar
        as={Menu}
        vertical
        animation='overlay'
        visible={props.visible}
        color='green'
        size='massive'
        style={{maxWidth: '50vh'}}
      >
        <MenuItem
          content='Dashboard'
          icon='dashboard'
          active={props.selectedMenu === 'dashboard'}
          onClick={(event, data) => props.onClickMenu(event, 'dashboard', data, '/')}
          style={{marginTop: '70px'}}
        />
        <MenuItem> Donasi
          <MenuMenu>
            <MenuItem
              content='Tambah Donasi'
              icon='list'
              active={props.selectedMenu === 'addDonation'}
              onClick={(event, data) => props.onClickMenu(event, 'addDonation', data, '/donation/donor')}
            />
            <MenuItem
              content='Daftar Donasi'
              icon='cart plus'
              active={props.selectedMenu === 'listDonation'}
              onClick={(event, data) => props.onClickMenu(event, 'listDonation', data, '/donation')}
            />
          </MenuMenu>
        </MenuItem>
        <MenuItem> Donatur
          <MenuMenu>
            <MenuItem
              content='Tambah Donatur'
              icon='user plus'
              active={props.selectedMenu === 'addDonor'}
              onClick={(event, data) => props.onClickMenu(event, 'addDonor', data, '/donor/create')}
            />
            <MenuItem
              content='Daftar Donatur'
              icon='list'
              active={props.selectedMenu === 'listDonor'}
              onClick={(event, data) => props.onClickMenu(event, 'listDonor', data, '/donor')}
            />
          </MenuMenu>
        </MenuItem>
        <MenuItem content='Logout' icon='sign-out' onClick={props.onHandleLogout}/>
      </SemanticSidebar>
      <SidebarPusher style={{minHeight: '100vh'}} onClick={props.onSidebarClose}>
        <Container style={{marginTop: '36px'}}>
          <Routes/>
        </Container>
      </SidebarPusher>
    </SidebarPushable>
  );
}
