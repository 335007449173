import * as React from 'react';

import http from '../utils/http';

import {Dropdown} from 'semantic-ui-react';

export interface ProgramDropdownState {
  programs: Array<any>,
  selectedValue?: string
}

export interface ProgramDropdownProps {
  selectedValue?: string,
  onChange: any
}

export default class ProgramDropdown extends React.Component<ProgramDropdownProps, ProgramDropdownState> {
  state = {
    programs: [],
    selectedValue: ''
  };

  async componentDidMount() {
    const {data: response} = await http.get('/funding/programs?paginated=false');

    this.setState({programs: response.data});
  }

  static getDerivedStateFromProps(nextProps: ProgramDropdownProps, prevState: ProgramDropdownState) {
    if (nextProps.selectedValue !== prevState.selectedValue) {
      return ({selectedValue: nextProps.selectedValue});
    }

    return null;
  };

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    event.preventDefault();

    const index = this.state.programs.findIndex((program: any) => program.id === data.value);

    data.program = index >= 0 ? this.state.programs[index] : {};

    this.props.onChange(event, data);
  };

  render() {
    const defaultValue = {key: '0', value: '', text: 'Pilih program (optional)'};

    const options = this.state.programs.length >= 1 ? [defaultValue].concat(this.state.programs.map((program: any) => ({
      key: program.id,
      value: program.id,
      text: program.name
    }))) : [defaultValue];

    return (
      <Dropdown
        fluid
        selection
        clearable
        options={options}
        name='program_id'
        placeholder='Pilih program (optional)'
        value={this.state.selectedValue}
        onChange={this.onChange}
      />
    );
  }
}
