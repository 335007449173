import * as React from 'react';

import http from '../utils/http';

import {Dropdown} from 'semantic-ui-react';

export interface OutletDropdownState {
  outlets: Array<any>,
  selectedValue?: string
}

export interface OutletDropdownProps {
  selectedValue?: string,
  onChange: any
}

export default class OutletDropdown extends React.Component<OutletDropdownProps, OutletDropdownState> {
  state = {
    outlets: [],
    selectedValue: ''
  };

  async componentDidMount() {
    const {data: response} = await http.get('/outlets');

    this.setState({outlets: response.data});
  }

  static getDerivedStateFromProps(nextProps: OutletDropdownProps, prevState: OutletDropdownState) {
    if (nextProps.selectedValue !== prevState.selectedValue) {
      return ({selectedValue: nextProps.selectedValue});
    }

    return null;
  };

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    event.preventDefault();

    const index = this.state.outlets.findIndex((outlet: any) => outlet.id === data.value);

    data.outlet = index >= 0 ? this.state.outlets[index] : {};

    this.props.onChange(event, data);
  };

  render() {
    const defaultValue = {key: '0', value: '', text: 'Pilih gerai'};

    const options = this.state.outlets.length >= 1 ? [defaultValue].concat(this.state.outlets.map((outlet: any) => ({
      key: outlet.id,
      value: outlet.id,
      text: outlet.name,
      data: outlet
    }))) : [defaultValue];

    return (
      <Dropdown
        fluid
        selection
        clearable
        options={options}
        name='outlet_id'
        placeholder='Pilih gerai'
        value={this.state.selectedValue}
        onChange={this.onChange}
      />
    );
  }
}
