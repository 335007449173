import http from './../utils/http';

export async function fetchDonation(query: string = '', page: number = 1) {
  const {data: response} = await http.get(`/donations?query=${query}&page=${page}`);

  return response;
}

export async function fetchDonor(query: string = '', page: number = 1) {
  const {data: response} = await http.get(`/donations/donors?query=${query}&page=${page}`);

  return response;
}
